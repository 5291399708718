import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useLazyChangeCardPinQuery, useLazySetCardPinQuery } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, checkFinalValue, tr, translateError, useFormValues } from 'mmfintech-commons'
import { Button, ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'

import '../cardSettings.scss'

interface EditCardPinProps {
  prepaidCardId?: string
  isSet?: boolean
  preselectedPin?: string
  referenceId?: string
}

const EditCardPin = ({ prepaidCardId, isSet = false, preselectedPin, referenceId }: EditCardPinProps) => {
  const [setCardPin, { error, isLoading }] = useLazySetCardPinQuery()
  const [changePin, { error: changePinError, isLoading: changePinLoading }] = useLazyChangeCardPinQuery()
  const [validationError, setValidationError] = useState<string>('')
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalHide, modalShow } = useContext(GlobalContext)
  const formValue = useFormValues({
    pin: { required: true },
    confirmPin: { required: true }
  })

  const handlePinChange = async () => {
    const pinCheck = checkFinalValue(formValue.getValue('pin'), { validation: 'numeric', minLength: 4, maxLength: 4 })
    setOtpOnSuccess(() => response => {
      setTimeout(() => {
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.CHANGE_PIN.TITLE', 'Change PIN'),
          content: (
            <EditCardPin
              prepaidCardId={prepaidCardId}
              isSet={true}
              preselectedPin={formValue.getValue('pin')}
              referenceId={response?.referenceId}
            />
          )
        })
      }, 100)
    })

    if (pinCheck.valid) {
      if (formValue.getValue('pin') !== formValue.getValue('confirmPin')) {
        return setValidationError(tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.ERROR', 'PIN numbers do not match'))
      }
      const result = await setCardPin({ prepaidCardId, pin: formValue.getValue('pin') }).unwrap()

      if (!result) {
        toast.success(tr('FRONTEND.CARDS.SETTINGS.SET_PIN.TOAST', 'Card pin set successfully'))
        setOtpOnSuccess(null)
        modalHide()
      }
    } else {
      return setValidationError(translateError(pinCheck))
    }
  }

  useEffect(() => {
    if (preselectedPin && referenceId) {
      formValue.setValue('pin', preselectedPin)
      formValue.setValue('confirmPin', preselectedPin)
      changePin({ pin: preselectedPin, referenceId })
        .unwrap()
        .then(() => {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.TOAST', 'Card pin changed successfully'))
          modalHide()
        })
    }
  }, [preselectedPin, referenceId])

  return (
    <div className='change-card-pin-wrapper'>
      {!changePinLoading ? (
        <div className='change-card-pin-form'>
          {/* <div className='change-card-pin-title'>
            {isSet
              ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.TITLE', 'Add new PIN')
              : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.TITLE', 'Set card PIN')}
          </div> */}
          <Input
            type='password'
            label={
              isSet
                ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.NEW_PIN', 'New PIN')
                : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.LABEL', 'Card PIN')
            }
            {...formValue.registerInput('pin', () => setValidationError(''))}
            autoComplete='new-password'
            disabled={!!preselectedPin}
          />
          <Input
            type='password'
            label={tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.CONFIRM_PIN', 'Confirm PIN')}
            {...formValue.registerInput('confirmPin', () => setValidationError(''))}
            autoComplete='new-password'
            disabled={!!preselectedPin}
          />
        </div>
      ) : (
        <div className='change-card-pin-loader'>
          <Preloader />
        </div>
      )}
      <ErrorDisplay error={[error?.cause && error?.cause[0]?.cause, error, validationError, changePinError]} />
      {!changePinLoading && (
        <Button
          type='button'
          text={
            isSet
              ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.REPLACE_BUTTON', 'Replace')
              : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.SET_BUTTON', 'Set')
          }
          loading={isLoading}
          onClick={handlePinChange}
        />
      )}
    </div>
  )
}

export default EditCardPin
