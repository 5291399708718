import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { breakpoints } from '../../constants'
import { GlobalContext, isValidArray, useWindowSize } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  resetEmailVerification,
  selectCurrentUserRole,
  selectInitialEmailVerification,
  useAppSelector,
  useGetPendingLegalRequirementsQuery,
  useGetSumSubStatusQuery
} from 'mmfintech-backend-api'

import WelcomeModal from './elements/WelcomeModal'
import { DashboardAdmin } from './DashboardAdmin'
import { DashboardStaff } from './DashboardStaff'
import { OnboardingBanner } from './elements/OnboardingBanner'
import { DashboardContainer } from './Dashboard.styled'
import { ColumnTwoSectionContainer } from '../../components/jetonStyled'
import { LegalRequirementsModal } from '../../components'

export const Dashboard = () => {
  const { modalShow } = useContext(GlobalContext)

  const customerRole = useAppSelector(selectCurrentUserRole)
  const initialEmailVerification = useAppSelector(selectInitialEmailVerification)

  useGetSumSubStatusQuery(null)

  const { data: legalRequirements, isFetching: legalRequirementsFetching } = useGetPendingLegalRequirementsQuery(null)

  const dispatch = useDispatch()
  const [width] = useWindowSize()

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(resetEmailVerification())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  useEffect(() => {
    if (!legalRequirementsFetching && isValidArray(legalRequirements)) {
      modalShow({
        options: {
          size: 'medium',
          transparent: false,
          closeOnEscape: true,
          closeOnClickOutside: true
        },
        content: <LegalRequirementsModal customerRole={customerRole} requirements={legalRequirements} />
      })
    }
  }, [legalRequirements])

  return (
    <DashboardContainer>
      {width > breakpoints.xl && <OnboardingBanner />}

      <ColumnTwoSectionContainer size='xl' additionalStyles={{ gap: '5rem', padding: '2rem 0' }}>
        {isOwnerOrAdministrator(customerRole) ? <DashboardAdmin /> : <DashboardStaff />}
      </ColumnTwoSectionContainer>
    </DashboardContainer>
  )
}
