import { useContext } from 'react'

import CurrencyIcon from '../../components/CurrencyIcon'
import FiatInvestModal from './FiatInvestModal'
import CryptoInvestModal from './CryptoInvestModal'

import { Button } from 'mmfintech-portal-commons'
import { PlanCardBox, PlanCardContent, PlanCardHeader, PlanCardWrapper } from './styled/planCard.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { GlobalContext, formatFloat, tr } from 'mmfintech-commons'

import InfoIcon from '../../images/icons/information-icon.svg?react'
import PercentIcon from '../../images/icons/percent.svg?react'
import HandCoinsIcon from '../../images/icons/hand-coins.svg?react'

interface PlanCardProps {
  currency: string
  reward: number
  minAmount: number
  type: 'crypto' | 'money'
}

const PlanCard = ({ currency, reward, minAmount, type }: PlanCardProps) => {
  const { modalShow } = useContext(GlobalContext)

  const { getCurrencyPrecision } = useCurrencies()
  const currencyDecimals = getCurrencyPrecision(currency)

  const handleInvest = (currency: string) => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: true, closeOnEscape: true },
      content: type == 'money' ? <FiatInvestModal currency={currency} /> : <CryptoInvestModal currency={currency} />
    })
  }

  return (
    <PlanCardWrapper>
      <PlanCardBox>
        <PlanCardHeader>
          <div className='currency-wrapper'>
            <CurrencyIcon currency={currency} width='3rem' height='3rem' circle />
            {currency}
          </div>

          <Button
            className={`invest-button ${type}`}
            type='button'
            color='secondary'
            text={tr('FRONTEND.SAVINGS.PLANS.BUTTONS.INVEST', 'Invest')}
            onClick={async () => handleInvest(currency)}
          />
        </PlanCardHeader>
        <PlanCardContent>
          <div>
            <div className='content-label'>
              <PercentIcon />
              {tr('FRONTEND.SAVINGS.PLANS.LABEL.APY', 'Est APY')}
              <InfoIcon
                className='info-icon'
                data-for='plan-tooltip'
                data-tip={'*APY rate reflects Annual Percentage Yield<br />and is subject to daily changes'}
              />
            </div>
            <div className='content-text'>{reward.toFixed(2) + '%'}</div>
          </div>
          <div>
            <div className='content-label'>
              <HandCoinsIcon />
              {tr('FRONTEND.SAVINGS.PLANS.LABEL.MIN_INVESTMENT', 'Minimum Investment')}
            </div>
            <div className='content-text'>{formatFloat(minAmount, currencyDecimals, currencyDecimals > 1 ? 2 : 0)}</div>
          </div>
        </PlanCardContent>
      </PlanCardBox>
    </PlanCardWrapper>
  )
}

export default PlanCard
