import CurrencyIcon from '../../components/CurrencyIcon'

import { AccountOverviewRowWrapper } from './styled/accountOverview.styled'

import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

type AccountOverviewRowProps = {
  account: {
    id: number
    currencyCode: string
    balance: number
    name: string
    permission?: string
  }
}
export function AccountOverviewRow({ account }: AccountOverviewRowProps) {
  const { currencyCode, balance, name, permission } = account

  const { getCurrencyPrecision } = useCurrencies()

  return (
    <AccountOverviewRowWrapper>
      <div className='cell'>
        <CurrencyIcon
          currency={currencyCode}
          width='3.4rem'
          height='3.4rem'
          className='mr-1 currency-icon'
          circle={false}
        />
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.CURRENCY', 'Currency')}
        </span>
        <span>{currencyCode}</span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.ACCOUNT_NAME', 'Account Name')}
        </span>
        <span className='subtitle'>{name}</span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.PERMISSIONS', 'Permissions')}
        </span>
        <span className='subtitle'>
          {permission === 'WRITER'
            ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.FULL.TITLE', 'Full')
            : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.VIEW_ONLY', 'View Only')}
        </span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.BALANCE', 'Balance')}
        </span>
        <span className='subtitle'>
          {formatMoney(balance || 0, currencyCode || '', getCurrencyPrecision(currencyCode))}
        </span>
      </div>
    </AccountOverviewRowWrapper>
  )
}
