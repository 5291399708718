import cn from 'classnames'

import { CurrencyIconWrapper } from './styled/currencyIcon.styled'

function CurrencyIcon({ currency, width = '3rem', height = '3rem', circle = false, className = '' }) {
  return (
    <CurrencyIconWrapper
      width={width}
      height={height}
      className={cn(className, {
        circle,
        [currency?.replace(/\./g, '-')]: true
      })}
    />
  )
}

export default CurrencyIcon
