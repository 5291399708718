import { useContext } from 'react'

import { CardOrderModal } from '../../../components/CardOrderModal'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { MerchantAccountTypeEnum /*OnboardingStatusEnum*/ } from 'mmfintech-commons-types'

import { NotOnboardedModal } from '../notOnboarded'
import './missingCard.scss'
import CardsIcon from '../../../images/cards-order-image.svg?react'
import { Button } from 'mmfintech-portal-commons'

const MissingCard = () => {
  const { modalShow, modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()
  const { /*onboardingStatus*/ accountType } = merchant || {}
  const handleOrderClick = () => {
    modalShow({
      // header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content:
        /* onboardingStatus != OnboardingStatusEnum.APPROVED ||*/ accountType == MerchantAccountTypeEnum.PROSPECT ? (
          <NotOnboardedModal onClose={modalHide} />
        ) : (
          <CardOrderModal onClose={modalHide} />
        ),
      options: {
        closeOnClickOutside: false,
        size: 'auto'
      }
    })
  }

  return (
    <div className='missing-cards'>
      <span className='missing-cards-title'>Cards</span>
      <div className='missing-card-container'>
        <div className='missing-card-content-wrapper'>
          <div className='missing-card-wrapper'>
            <div className='missing-card-text'>
              <h4>{tr('FRONTEND.CARDS.MISSING.TITLE', 'Jetonbank Cards')}</h4>
              <p>
                {tr(
                  'FRONTEND.CARDS.MISSING.CONTENT',
                  'A contactless debit card will be delivered to you. Your card balance will be GBP with a new account.'
                )}
              </p>
            </div>
            <CardsIcon />
          </div>
          <div className='missing-card-actions'>
            <Button
              className='order-card-button'
              text={tr('FRONTEND.CARDS.MISSING.BUTTON.ORDER', 'Continue')}
              onClick={handleOrderClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MissingCard
