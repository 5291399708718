import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import CurrencyIcon from './CurrencyIcon'
import { AccountLabelWrapper, AccountsPaneWrapper } from './styled/accountsPanel.styled'

import { cutString, formatMoney, isValidArray } from 'mmfintech-commons'
import { paths, useCurrencies, useMerchantAccounts } from 'mmfintech-backend-api'

export function AccountsPanel() {
  const { accounts: paymentAccounts, accountsFetching } = useMerchantAccounts()
  return (
    <AccountsPaneWrapper>
      <div className='your-accounts-text'>Accounts</div>
      {accountsFetching ? (
        <Skeleton count={3} height={30} />
      ) : isValidArray(paymentAccounts) ? (
        <>
          {paymentAccounts.slice(0, 4).map((account: any) => (
            <AccountLabel account={account} key={account.id} />
          ))}
        </>
      ) : null}
    </AccountsPaneWrapper>
  )
}

function AccountLabel({ account }: { account: any }) {
  const { currencyCode, balance, name, id } = account

  const history = useHistory()
  const { getCurrencyPrecision } = useCurrencies()

  return (
    <AccountLabelWrapper onClick={() => history.push(paths.banking.transactions.listByAccountId(id))}>
      <div className='left'>
        <CurrencyIcon currency={currencyCode} circle className='currency-icon' />
        <div className='name-wrapper'>
          <div className='label'>{currencyCode}</div>
          <div className='name'>{cutString(name, 15)}</div>
        </div>
      </div>
      <div className='balance'>{formatMoney(balance, currencyCode, getCurrencyPrecision(currencyCode))}</div>
    </AccountLabelWrapper>
  )
}
