import { useMemo, useState } from 'react'

import moment from 'moment'

import { Button, Input } from 'mmfintech-portal-commons'

import { checkSingleValue, isValidArray, tr, useFilter } from 'mmfintech-commons'
import {
  getTransactionStatusOptions,
  useCurrencies,
  useMerchantAccounts,
  usePaymentMethods
} from 'mmfintech-backend-api'

import SelectAccount from '../../../components/SelectAccount'
import { FilterButtons, FilterWrapper, FiltersContainer } from './styled/transactionsFilter.styled'

import CalendarIcon from '../../../images/icons/filter-calendar.svg?react'
import CloseIcon from '../../../images/icons/icon-close-transparent.svg?react'

function TransactionsFilter({ accountId, applyFilter, modalHide = null }) {
  const { currencyOptionsSimple } = useCurrencies()
  const { paymentMethodOptions } = usePaymentMethods()
  const { accounts: paymentAccounts } = useMerchantAccounts()

  const values = useFilter(
    {
      ...(accountId > 0 ? { accountId } : null),
      from: moment().add(-1, 'month').toDate(),
      to: moment().toDate()
    },
    ['reference', 'description', 'customerEmail'],
    ['from', 'to', 'statuses']
  )
  const clearAll = () => {
    values.reset()
    values.set('paymentMethods', [])
  }
  const [selectedAccount, setSelectedAccount] = useState(null)
  const getCurrencyOptions = () => [
    { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.ALL_CURRENCIES', 'All') },
    ...currencyOptionsSimple
  ]

  useMemo(() => {
    if (isValidArray(paymentAccounts) && !selectedAccount) {
      let find = null
      if (accountId) {
        find = paymentAccounts.find(account => account.id === accountId)
      } else if (values.get('accountId')) {
        find = paymentAccounts.find(account => account.id === Number(values.get('accountId')))
      }
      setSelectedAccount(find)
    }
    // eslint-disable-next-line
  }, [paymentAccounts])

  const handleSelectAccount = (account: any) => {
    const { id } = account || {}
    setSelectedAccount(account)
    values.set('accountId', id)
    // !width < breakpoints.xl && filter.values.reload()
  }
  return (
    <FilterWrapper>
      <FiltersContainer>
        <div className='top-row'>
          <h3>{tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters')}</h3>
          {typeof modalHide === 'function' && <CloseIcon onClick={modalHide} />}
        </div>

        <SelectAccount
          // showAllAccounts
          accounts={paymentAccounts}
          label={tr('FRONTEND.TRANSACTIONS.FILTER.ACCOUNT', 'Account')}
          selectedAccount={selectedAccount}
          setSelectedAccount={handleSelectAccount}
        />

        <div className='multiple-inputs mt-2'>
          <Input
            type='date'
            name='from'
            maxDate={new Date(values.get('to'))}
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_DATE', 'From date')}
            {...values.registerInput('from')}
            data-test='filter-from-date'
            icon={<CalendarIcon />}
            hideErrorLine
          />
          <Input
            type='date'
            name='to'
            minDate={new Date(values.get('from'))}
            maxDate={moment().toDate()}
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_DATE', 'To date')}
            {...values.registerInput('to')}
            data-test='filter-to-date'
            icon={<CalendarIcon />}
            hideErrorLine
          />
        </div>

        <div className='multiple-inputs'>
          <Input
            type='number'
            name='id'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TRANSACTION_ID', 'ID')}
            {...values.registerInput('id')}
            data-test='filter-id'
            hideErrorLine
            forceLabel
          />

          <Input
            type='text'
            name='reference'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.REFERENCE', 'Reference')}
            {...values.registerInput('reference')}
            data-test='filter-reference'
            hideErrorLine
            forceLabel
          />
        </div>

        <Input
          type='text'
          name='foreignTransactionId'
          label={tr('FRONTEND.TRANSACTIONS.FILTER.FOREIGN_ID', 'Foreign ID')}
          {...values.registerInput('foreignTransactionId')}
          data-test='filter-foreign-id'
          hideErrorLine
          forceLabel
          styles={styles}
        />

        <Input
          type='text'
          name='description'
          label={tr('FRONTEND.TRANSACTIONS.FILTER.CUSTOMER_EMAIL', 'Customer email')}
          {...values.registerInput('description')}
          data-test='filter-customer'
          hideErrorLine
          forceLabel
          styles={styles}
        />

        <div className='multiple-inputs'>
          <Input
            type='select'
            name='paymentMethods'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.PAYMENT_METHOD', 'Payment method')}
            {...values.registerInput('paymentMethods')}
            options={paymentMethodOptions}
            data-test='filter-payment-method'
            hideErrorLine
            isMultiSelect
            isSearchable={false}
            forceLabel
          />
          <Input
            type='select'
            name='statuses'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.STATUS', 'Status')}
            {...values.registerInput('statuses')}
            options={[
              { value: '', label: tr('FRONTEND.TRANSACTIONS.FILTER.STATUS_ALL', 'All statuses') },
              ...getTransactionStatusOptions()
            ]}
            data-test='filter-status'
            hideErrorLine
            isSearchable={false}
            forceLabel
          />
        </div>

        <div className='multiple-inputs'>
          <Input
            type='select'
            name='currency'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.CURRENCY', 'Currency')}
            {...values.registerInput('currency')}
            options={getCurrencyOptions()}
            data-test='filter-currency'
            hideErrorLine
            forceLabel
          />
          <Input
            type='text'
            name='fromAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM_AMOUNT', 'From amount')}
            value={values.get('fromAmount')}
            onChange={(name: string, value: string) => {
              values.set(name, checkSingleValue(value, { validation: 'float' }))
            }}
            data-test='filter-from-amount'
            hideErrorLine
            forceLabel
          />
          <Input
            type='text'
            name='toAmount'
            label={tr('FRONTEND.TRANSACTIONS.FILTER.TO_AMOUNT', 'To amount')}
            value={values.get('toAmount')}
            onChange={(name: string, value: string) => {
              values.set(name, checkSingleValue(value, { validation: 'float' }))
            }}
            data-test='filter-to-amount'
            hideErrorLine
            forceLabel
          />
        </div>
      </FiltersContainer>

      <FilterButtons>
        <Button
          color='round-secondary'
          text={tr('FRONTEND.TRANSACTIONS.FILTER.APPLY_BUTTON', 'Apply')}
          onClick={() => applyFilter(values)}
          data-test='button-apply'
        />
        <Button
          id='clear-all'
          type='button'
          color='delete'
          onClick={() => {
            clearAll()
            setSelectedAccount(null)
            applyFilter()
          }}
          text={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Clear all')}
          data-test='reset-filter-button'
        />
      </FilterButtons>
    </FilterWrapper>
  )
}

const styles = {
  container: {
    width: '100%'
  }
}

export default TransactionsFilter
