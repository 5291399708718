import React, { useEffect } from 'react'

import {
  InputAmountElement,
  InputAmountError,
  InputAmountLabel,
  InputAmountLimits,
  InputAmountStatic,
  InputAmountWrapper
} from './styled/inputAmount.styled'

import { checkSingleValue, formatFloat, isValidFunction, tr } from 'mmfintech-commons'

import {
  AccountBalanceOut,
  PaymentOption,
  PaymentOptionResponse,
  PaymentOptionStatusEnum
} from 'mmfintech-commons-types'
import { useCurrencies } from 'mmfintech-backend-api'

interface InputAmountProps {
  label?: string
  amount?: string
  account: AccountBalanceOut
  setAmount?: (amount: string) => void
  paymentOption?: PaymentOption | PaymentOptionResponse
  placeholder?: string
  error?: string
  setAmountError?: (arg: any) => void
  dataTest: string
}

function InputAmount(props: InputAmountProps) {
  const { label, amount, account, setAmount, paymentOption, error, setAmountError, placeholder, dataTest, ...rest } =
    props
  const { currencyCode } = account || {}

  const { getCurrencyPrecision } = useCurrencies()
  const decimals = getCurrencyPrecision(currencyCode)

  const getLimits = () => {
    const { status, minAmount, maxAmount } = paymentOption || {}

    if (status === PaymentOptionStatusEnum.AVAILABLE) {
      const res = []

      if (minAmount > 0) {
        res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MIN', 'Min')}: ${formatFloat(minAmount, decimals)}`)
      }
      if (maxAmount > 0) {
        res.push(`${tr('FRONTEND.MONEY_INPUT.LABEL_MAX', 'Max')}: ${formatFloat(maxAmount, decimals)}`)
      }

      return res.length > 0 && res.join(', ')
    }

    return null
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidFunction(setAmount)) {
      setAmount(checkSingleValue(e.target.value, { validation: 'float', decimals }))
      setAmountError && setAmountError(null)
    }
  }

  useEffect(() => {
    if (currencyCode && amount && setAmount) {
      setAmount(checkSingleValue(amount, { validation: 'float', decimals }))
    }
  }, [currencyCode])

  return (
    <InputAmountWrapper {...rest}>
      <InputAmountLabel>{label}</InputAmountLabel>

      {isValidFunction(setAmount) ? (
        <InputAmountElement
          type='text'
          value={amount || ''}
          onChange={handleAmountChange}
          placeholder={placeholder || '0'}
          maxLength={16}
          data-test={dataTest}
          aria-label='amount'
        />
      ) : (
        <InputAmountStatic data-test={dataTest}>{amount || 0}</InputAmountStatic>
      )}

      {error && error?.length && <InputAmountError>{error}</InputAmountError>}
      <InputAmountLimits>{getLimits() || ' '}</InputAmountLimits>
    </InputAmountWrapper>
  )
}

export default InputAmount
