import React from 'react'

import { paths } from 'mmfintech-backend-api'

import Accounts from './views/account/Accounts'
import Dashboard from './views/dashboard'
import Expenses from './views/requests/Requests'
import Invoices from './views/invoices/Invoices'
import Login from './views/auth/Login'
import ReferralProgram from './views/referral/ReferralProgram'
import Savings from './views/savings/Savings'
import Transactions from './views/transactions/Transactions'
import Card from './views/card'

const BusinessAccount = React.lazy(() => import('./views/businessAccount/BusinessAccount'))
const Exchange = React.lazy(() => import('./views/banking/Exchange'))
const Invite = React.lazy(() => import('./views/auth/Invite'))
const InvoiceEdit = React.lazy(() => import('./views/invoices/InvoiceEdit'))
const InvoiceUploadedEdit = React.lazy(() => import('./views/invoices/InvoiceUploadedEdit'))
const LazySignup = React.lazy(() => import('./views/auth/Signup'))
const TransactionFinalStatus = React.lazy(() => import('./views/static/TransactionFinalStatus'))
const VerificationSumSub = React.lazy(() => import('./views/onboarding/SumSubVerification'))
const Withdraw = React.lazy(() => import('./views/banking/SendMoney/SendMoney'))

// prettier-ignore
const routes = [
  { path: paths.root(), redirect: paths.dashboard() },

  { path: paths.banking.transferSuccess(), PrivateComponent: React.lazy(() => import('./views/banking/elements/SuccessPane')) },

  { path: paths.dashboard(), PrivateComponent: Dashboard },
  { path: paths.profile(), PrivateComponent: React.lazy(() => import('./views/settings/Profile')) },
  { path: paths.security(), PrivateComponent: React.lazy(() => import('./views/settings/Security')) },
  { path: paths.userManagement(), PrivateComponent: React.lazy(() => import('./views/users')) },
  { path: paths.banking.depositPreview(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/preview/DepositPreview')) },
  { path: paths.banking.deposit(), PrivateComponent: React.lazy(() => import('./views/banking/deposit/Deposit')) },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), PrivateComponent: Exchange },
  { path: paths.banking.exchange(), PrivateComponent: Exchange },
  { path: paths.banking.sendBankTransfer(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/BankTransfer')) },
  { path: paths.banking.sendCrypto(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/CryptoTransfer')) },
  { path: paths.banking.sendTransfer(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/InternalTransfer')) },
  { path: paths.banking.sendDigitalWallet(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/WalletTransfer')) },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/IntraAccountTransfer')) },
  { path: paths.banking.sendBatchPayment(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/BatchPayment')) },
  { path: paths.banking.sendTo(':recipientId'), PrivateComponent: Withdraw },
  { path: paths.banking.send(), PrivateComponent: Withdraw },
  { path: paths.banking.expenses(), PrivateComponent: Expenses },
  { path: paths.ibanIssuing(), exact: true, PrivateComponent: React.lazy(() => import('./views/account/IbanRequest')) },
  { path: paths.ibanIssuingPreview(), exact: true, PrivateComponent: React.lazy(() => import('./views/banking/preview/IbanRequestPreview')) },

  { path: paths.banking.transactions.details(':transactionId'), PrivateComponent: React.lazy(() => import('./views/transactions/TransactionDetails')) },
  { path: paths.recipients.details(':recipientId'), PrivateComponent: React.lazy(() => import('./views/recipients/RecipientDetails')) },
  { path: paths.recipients.list(), PrivateComponent: React.lazy(() => import('./views/recipients/Recipients')) },
  { path: paths.banking.accounts.list(), PrivateComponent: Accounts },
  { path: paths.banking.transactions.listByAccountId(':accountId'), PrivateComponent: Transactions },
  { path: paths.banking.transactions.list(), PrivateComponent: Transactions },
  { path: paths.referralProgram(), PrivateComponent: ReferralProgram },
  { path: paths.more.savings(), PrivateComponent: Savings },

  { path: paths.developer(), PrivateComponent: React.lazy(() => import('./views/developer/Developer')) },

  { path: paths.customerInvoices(':payerId'), PrivateComponent: React.lazy(() => import('./views/customers/CustomerDetails')) },
  { path: paths.customers(), PrivateComponent: React.lazy(() => import('./views/customers/Customers')) },

  { path: paths.invoices.viewByToken(':magicToken'), PrivateComponent: React.lazy(() => import('./views/invoices/InvoicePreview')) },

  { path: paths.invoices.uploadEdit(':invoiceId'), PrivateComponent: InvoiceUploadedEdit },
  { path: paths.invoices.upload(), PrivateComponent: InvoiceUploadedEdit },
  { path: paths.invoices.addForCustomer(':customerId'), PrivateComponent: InvoiceEdit },
  { path: paths.invoices.add(), PrivateComponent: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), PrivateComponent: React.lazy(() => import('./views/invoices/InvoiceSend')) },
  { path: paths.invoices.edit(':invoiceId'), PrivateComponent: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), PrivateComponent: Invoices },
  { path: paths.invoices.list(), PrivateComponent: Invoices },

  { path: paths.onboarding.documents(), exact: true, PrivateComponent: React.lazy(() => import('./views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, PrivateComponent: React.lazy(() => import('./views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.questionnaireKybSuccess(), exact: true, PrivateComponent: React.lazy(() => import('./views/onboarding/elements/KybSuccess')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, PrivateComponent: VerificationSumSub },
  { path: paths.onboarding.sumSub.continue(), exact: true, PrivateComponent: VerificationSumSub },
  { path: paths.card(), PrivateComponent: Card },
]

// prettier-ignore
const nonProtectedRoutes = [
  { path: paths.login(), Component: Login },
  { path: paths.signUpViaInviteLink(':referralCode'), Component: LazySignup },
  { path: paths.signUp(), Component: LazySignup },
  { path: paths.inviteJeton(':link'), exact: true, Component: Invite },
  { path: paths.invite(':link'), exact: true, Component: Invite },
  { path: paths.checkEmail(), Component: React.lazy(() => import('./views/static/CheckEmail')) },
  { path: paths.forgotPassword(), Component: React.lazy(() => import('./views/auth/ForgotPassword')) },
  { path: paths.businessAccount(), Component: BusinessAccount },

  { path: paths.changePasswordEmail(), Component: React.lazy(() => import('./views/static/ForgottenPasswordEmail')) },
  { path: paths.passwordReset(), Component: React.lazy(() => import('./views/static/PasswordResetMessage')) },
  { path: paths.handlePasswordReset(), Component: React.lazy(() => import('./views/auth/ResetPassword')) },
  { path: paths.handlePasswordResetInvited(), Component: React.lazy(() => import('./views/auth/SetPassword')) },
  { path: paths.handleEmailVerify(), Component: React.lazy(() => import('./views/auth/VerifyEmail')) },
  { path: paths.handleInvitationVerify(), Component: React.lazy(() => import('./views/auth/VerifyInvitation')) },
  { path: paths.invoices.checkout(':magicToken'), Component: React.lazy(() => import('./views/invoices/InvoiceCheckout')) },
  { path: paths.offshoreBank(), Component: BusinessAccount },

  { path: paths.error404(), Component: React.lazy(() => import('./views/static/Error404')) },

  { path: paths.banking.finalStatus.fail(':method'), Component: TransactionFinalStatus, params: { isSuccess: false } },
  { path: paths.banking.finalStatus.success(':method'), Component: TransactionFinalStatus, params: { isSuccess: true } }
]

export { routes, nonProtectedRoutes }
