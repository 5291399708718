import { formatMoney } from 'mmfintech-commons'

import CurrencyIcon from '../../components/CurrencyIcon'
import { AmountContainer, AmountPaneWrapper } from './styled/referralAmountPane.styled'

function ReferralAmountPane({ amount, currency, title }) {
  return (
    <AmountPaneWrapper>
      <div className='pane-title'>{title}</div>
      <AmountContainer>
        <CurrencyIcon width='26px' height='26px' currency={currency} circle />
        <div className='amount-currency'>
          <span className='amount'>{formatMoney(amount, currency, 2)}</span>
          <span className='currency'>{currency}</span>
        </div>
      </AmountContainer>
    </AmountPaneWrapper>
  )
}

export default ReferralAmountPane
