import { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

// import CloudIcon from '@images/icons/cloud.svg?react'
import CopyIcon from '@images/icons/copy_icon_v2.svg?react'
import MastercardLogo from '@images/icons/mastercard-logo.svg?react'
import UnionPayLogo from '@images/icons/unionPay_logo.svg?react'
import TKBLogo from '@images/logo.svg?react'
import cn from 'classnames'

import { copyTextToClipboard, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import './rotatingCard.scss'

enum CardBrandEnum {
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY'
}

const CardLogo = (cardBrand: string) => {
  switch (cardBrand) {
    case CardBrandEnum.MASTERCARD:
      return <MastercardLogo className='mastercard-logo' />
    case CardBrandEnum.UNIONPAY:
      return <UnionPayLogo className='unionpay-logo' />
    default:
      return <UnionPayLogo className='unionpay-logo' />
  }
}

interface RotatingCardProps {
  cardNumber?: number | string
  lastFourDigits?: number | string
  validity?: string
  cvv?: number
  flip?: { detailsShown: boolean; actionCalled: number }
  enableRotation?: boolean
  setVisibleSide?: (visibleSide: 'front' | 'back') => void
  visibleSide?: 'front' | 'back'
  cardType?: 'virtual' | 'physical'
  isFrozen?: boolean
  hasShadow?: boolean
  cardBrand?: string
  cardHolderName?: string
}

export const RotatingCard = ({
  cardNumber,
  lastFourDigits,
  validity,
  cvv,
  flip,
  setVisibleSide,
  enableRotation = false,
  visibleSide,
  cardType,
  isFrozen,
  hasShadow = true,
  cardBrand,
  cardHolderName
}: RotatingCardProps) => {
  const [prevPosition, setPrevPosition] = useState(0)
  const [calcPosition, setCalcPosition] = useState(0)
  const sensitivity = 3 //arbitrary value that handles the speed of the rotation

  const sectionRef = useRef<HTMLDivElement>()
  const bookRef = useRef<HTMLDivElement>()

  const handleRotation = useCallback(e => {
    e.preventDefault()
    let initialCoordinates = e.clientX
    const body = document.body

    const rotate = e => {
      const currentCoordinates = e.clientX
      setCalcPosition(((currentCoordinates - initialCoordinates) / sensitivity) % 360)

      setPrevPosition(value => {
        return (value + (currentCoordinates - initialCoordinates) / sensitivity) % 360
      })

      body.style.cursor = 'grabbing'
      bookRef.current.style.transition = 'unset'

      initialCoordinates = currentCoordinates
    }

    sectionRef.current.addEventListener('mousemove', rotate)

    const cancelRotation = () => {
      sectionRef.current.removeEventListener('mousemove', rotate)
      bookRef.current.style.transition = 'all 500ms cubic-bezier(0.6, 0.4, 0.3, 1.7)'
      body.style.cursor = 'default'
    }

    sectionRef.current.addEventListener('mouseup', cancelRotation)
    sectionRef.current.addEventListener('mouseleave', cancelRotation)
  }, [])

  useEffect(() => {
    const rotationAngle = calcPosition + prevPosition
    if (flip?.detailsShown === true) {
      if (rotationAngle < -180) {
        return setPrevPosition(-180)
      }
      setPrevPosition(180)
    } else {
      setPrevPosition(0)
    }
    setCalcPosition(0)
  }, [flip])

  useEffect(() => {
    const rotationAngle = calcPosition + prevPosition
    if (rotationAngle > 360 || rotationAngle < -360) return
    if (
      (rotationAngle < 90 && rotationAngle > -90) ||
      (rotationAngle < -270 && rotationAngle > -360) ||
      (rotationAngle > 270 && rotationAngle < 360)
    ) {
      setVisibleSide('front')
    } else {
      setVisibleSide('back')
    }
  }, [prevPosition, calcPosition])

  useEffect(() => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.removeEventListener('mousedown', handleRotation)
      if (enableRotation) {
        sectionRef.current.addEventListener('mousedown', handleRotation)
      }
    }
  }, [enableRotation])

  return (
    <div className='rotating-card-section' ref={sectionRef}>
      <div className='rotating-card-wrapper'>
        <div
          className={cn('rotating-card', { physical: cardType === 'physical', frozen: isFrozen })}
          ref={bookRef}
          style={{ transform: `rotateY(${calcPosition + prevPosition}deg)` }}>
          <div className={cn('rotating-card-frozen')}>
            <div className='rotating-card-frost'></div>
          </div>
          <div className='card-front'>
            <div className='rotating-card-front-top-data'>
              <div className='rotating-card-holder-name'>{cardHolderName}</div>
              {cardHolderName && (
                <Button
                  className='rotating-card-copy-button'
                  icon={<CopyIcon />}
                  onClick={e => {
                    e.stopPropagation()
                    if (visibleSide == 'back') {
                      copyTextToClipboard(
                        cardHolderName.toString(),
                        toast.success(
                          tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                          {
                            position: 'top-right'
                          }
                        )
                      )
                    }
                  }}
                />
              )}
            </div>
            <div className='rotating-card-front-middle-data'>
              <div className='rotating-card-number'>
                {cardNumber &&
                  cardNumber
                    .toString()
                    .match(/.{1,4}/g)
                    .join(' ')}
              </div>
              {cardNumber && (
                <Button
                  className='rotating-card-copy-button'
                  icon={<CopyIcon />}
                  onClick={e => {
                    e.stopPropagation()
                    if (visibleSide == 'back') {
                      copyTextToClipboard(
                        cardNumber.toString(),
                        toast.success(
                          tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                          {
                            position: 'top-right'
                          }
                        )
                      )
                    }
                  }}
                />
              )}
            </div>
            <div className='rotating-card-front-bottom-data'>
              <div className='rotating-card-validity'>
                <label className='rotating-card-validity-label'>VALID THRU</label>
                {validity}
              </div>
              <div className='rotating-card-cvv'>
                <label className='rotating-card-cvv-label'>CVC</label>
                {cvv}
              </div>
              {cvv && (
                <Button
                  className='rotating-card-copy-button'
                  icon={<CopyIcon />}
                  onClick={e => {
                    e.stopPropagation()
                    if (visibleSide == 'back') {
                      copyTextToClipboard(
                        cvv.toString(),
                        toast.success(
                          tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                          {
                            position: 'top-right'
                          }
                        )
                      )
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className='card-left-side'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-right-side'></div>
          <div className='card-back'>
            <TKBLogo className='tkb-logo' />
            {CardLogo(cardBrand)}
            <div className='card-back-number'>{lastFourDigits && '* ' + lastFourDigits}</div>
          </div>
          {hasShadow && <div className='rotating-card-shadow'></div>}
        </div>
      </div>
    </div>
  )
}
