import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { paths, useLazyGetTransactionsQuery, useTransactionColumns } from 'mmfintech-backend-api'
import { fixDateOnly, GlobalContext, isValidArray, tr, usePaginationQuery, useWindowSize } from 'mmfintech-commons'
import { Button, ErrorDisplay, NoElementsMessage, Pagination, Spinner } from 'mmfintech-portal-commons'

import Callout, { CalloutPropType } from '../../components/Callout'
import { CommonPageTitle, RowsMainSection, RowsTopSection, RowsTwoSectionContainer } from '../../components/jetonStyled'
import { breakpoints } from '../../constants'
import settings from '../../settings'
import CustomizeTableModal from './elements/CustomizeTableModal'
import { TransactionsExportButtons, TransactionsExportButtonsInterface } from './elements/TransactionsExportButtons'
import TransactionsFilter from './elements/TransactionsFilter'
import TransactionsTable from './elements/TransactionsTable'
import { SelectionNumber, TransactionsHeader, TransactionsHeaderRight } from './styled/transactions.styled'

import CustomizeIcon from '../../images/icons/customize-table.svg?react'
import FilterIcon from '../../images/icons/filter-icon.svg?react'
import RecipientsIcon from '../../images/icons/recipients.svg?react'

function Transactions({ match }) {
  const accountId = parseInt(match.params.accountId)
  const columns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: true,
    enableFailReason: true,
    defaultVisible: ['id', 'type', 'description', 'reference', 'status', 'customerEmail', 'customerName']
  })
  const { modalShow, modalHide, sidebarInnerRightShow, sidebarInnerRightHide } = useContext(GlobalContext)

  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })
  const [width] = useWindowSize()
  const history = useHistory()
  const [exportParameters, setExportParameters] = useState<TransactionsExportButtonsInterface>()

  const pagination = usePaginationQuery({
    rowsPerPage: 10,
    reload: async (params, onSuccess = null) => {
      try {
        const response = await fetchTransactions(prepareFilter(params)).unwrap()
        if (response) {
          onSuccess(response)
        }
      } catch (_err) {}
    }
  })

  const prepareFilter = params => {
    const { values, ...rest } = params
    return {
      ...rest,
      accountId,
      ...(values
        ? {
            ...values.prepare(),
            ...(values.get('statuses') ? { statuses: values.get('statuses').split(',') } : null),
            from: fixDateOnly(values.get('from')),
            to: fixDateOnly(values.get('to'))
          }
        : {}),
      sort: 'reqTime-desc,id-desc'
    }
  }

  const applyFilter = values => {
    if (values) {
      setExportParameters({
        accountId: values.get('accountId'),
        from: values.get('from'),
        to: values.get('to')
      })
    }

    //@ts-ignore
    pagination.reload({ values })
    modalHide()
  }
  const handleRecipientsClick = () => history.push(paths.recipients.list())

  const handleShowFilters = () => {
    modalShow({
      content: <TransactionsFilter accountId={accountId} applyFilter={applyFilter} modalHide={modalHide} />
    })
  }

  const handleCustomizeClick = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto' },
      content: <CustomizeTableModal columns={columns} onClose={modalHide} />
    })
  }

  useEffect(() => {
    if (width >= breakpoints.xl) {
      sidebarInnerRightShow({
        content: <TransactionsFilter accountId={accountId} applyFilter={applyFilter} />,
        options: { size: width > breakpoints['2xl'] ? 'extra-large' : 'large' }
      })
    } else {
      sidebarInnerRightHide()
    }
    return () => {
      sidebarInnerRightHide()
    }
  }, [width])

  const calloutProps: CalloutPropType = {
    isOpen: true,
    position: 'top',
    alignment: 'right',
    arrowOptions: { showArrow: false },
    offset: { x: '3.5rem', y: '0rem' },
    content: columns.isModified() ? <SelectionNumber>{columns.visible.length}</SelectionNumber> : null
  }

  return (
    <RowsTwoSectionContainer size='lg'>
      <RowsTopSection>
        <TransactionsHeader>
          {width >= breakpoints.xl && (
            <CommonPageTitle className='title' centered>
              {tr('FRONTEND.TRANSACTIONS.TITLE', 'Transactions')}
            </CommonPageTitle>
          )}
          <TransactionsHeaderRight>
            <div className='buttons-container'>
              <Button
                color='round-secondary'
                icon={<RecipientsIcon />}
                text={tr('FRONTEND.TRANSACTIONS.RECIPIENTS_BUTTON', 'Recipients')}
                onClick={handleRecipientsClick}
                data-test='button-deposit'
              />
              <Button
                type='button'
                color='round-secondary'
                onClick={handleCustomizeClick}
                className={cn({ modified: columns.isModified() })}
                text={
                  <Callout {...calloutProps}>
                    {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_BUTTON', 'Customize')}
                  </Callout>
                }
                data-test='customize-columns'
                icon={<CustomizeIcon />}
              />
            </div>
            {width < breakpoints.xl && (
              <div className='filter-icon' onClick={handleShowFilters}>
                <FilterIcon />
              </div>
            )}
          </TransactionsHeaderRight>
        </TransactionsHeader>
      </RowsTopSection>
      <RowsMainSection>
        {transactionsFetching ? (
          <Spinner />
        ) : transactionsError ? (
          <ErrorDisplay error={transactionsError} />
        ) : isValidArray(transactions) ? (
          <>
            <TransactionsTable columns={columns} transactions={transactions} />
            <div className='pagination-buttons-container'>
              <Pagination className='transactions-pagination' {...pagination.register()} />
              <TransactionsExportButtons {...exportParameters} />
            </div>
          </>
        ) : (
          <NoElementsMessage
            message={tr(
              'FRONTEND.TRANSACTIONS.NO_TRANSACTIONS',
              'No transactions for the selected period or search criteria'
            )}
          />
        )}
      </RowsMainSection>
    </RowsTwoSectionContainer>
  )
}

export default Transactions
