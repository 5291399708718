import { StepperWrapper } from './CardOrderStepper.style'

export const OrderCardStepper = ({
  currentStep,
  maxStep
}: {
  currentStep: number
  maxStep: {
    step: number
    label: string
  }[]
}) => {
  return (
    <StepperWrapper>
      {maxStep.map(({ step, label }) => {
        return (
          <div key={step} className={`single-step-wrapper ${step === currentStep ? 'active' : ''} `}>
            <div className='--step'>
              <div>{step}</div>
            </div>
            <div className='--label'>{label}</div>
          </div>
        )
      })}
    </StepperWrapper>
  )
}
