import React from 'react'

import { NavLink } from 'react-router-dom'

import styled from 'styled-components'

import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  selectMerchant,
  // selectMerchantCapabilities,
  useAppSelector,
  useAuth
} from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { breakpoints } from '../../constants'
import useHideHeaders from '../../hooks/useHideHeaders'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import AccountsIcon from '../../images/icons/SidebarIcons/accounts.svg?react'
import ExpensesIcon from '../../images/icons/SidebarIcons/expenses.svg?react'
// import ForexIcon from '../../images/icons/SidebarIcons/forex.svg?react'
import InviteAndEarnIcon from '../../images/icons/SidebarIcons/invite-and-earn.svg?react'
import InvoicesIcon from '../../images/icons/SidebarIcons/invoices.svg?react'
import OverviewIcon from '../../images/icons/SidebarIcons/overview.svg?react'
import SavingsIcon from '../../images/icons/SidebarIcons/savings.svg?react'
import TransactionsIcon from '../../images/icons/SidebarIcons/transactions.svg?react'
import CardMenuIcon from '../../images/icons/SidebarIcons/card-menu-icon.svg?react'

function SideBar() {
  const userStatus = useAuth()
  const visible = useHideHeaders()
  const customerRole = useAppSelector(selectCurrentUserRole)
  // const capabilities = useAppSelector(selectMerchantCapabilities)
  const { data } = useAppSelector(selectMerchant)
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const { capabilities } = data || {}

  const NavigationLink: React.FC<{
    to: string
    label: string
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
    onClick?: () => void
  }> = ({ to, label, icon: Icon, onClick }) => {
    return (
      <NavLink to={to} onClick={onClick} className='sidebar-link'>
        <div>
          <Icon />
        </div>
        <span className='sidebar-link-text'>{tr(label)}</span>
      </NavLink>
    )
  }

  return (
    <SidebarContainer isOpen visible={visible}>
      {isLoggedIn() && (
        <div className='content'>
          <NavigationLink
            to={paths.dashboard()}
            label={tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
            icon={OverviewIcon} // Replace with your icon
          />
          <NavigationLink
            to={paths.banking.accounts.list()}
            label={tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
            icon={AccountsIcon} // Replace with your icon
          />
          <NavigationLink
            to={paths.banking.transactions.list()}
            label={tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
            icon={TransactionsIcon} // Replace with your icon
          />
          <NavigationLink
            to={paths.banking.expenses()}
            label={tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
            icon={ExpensesIcon} // Replace with your icon
          />
          <NavigationLink
            to={paths.invoices.list()}
            label={tr('FRONTEND.HEADER.INVOICES', 'Invoices')}
            icon={InvoicesIcon} // Replace with your icon
          />
          {isOwnerOrAdministrator(customerRole) && capabilities?.allowReferrals && (
            <NavigationLink
              to={paths.referralProgram()}
              label={tr('FRONTEND.HEADER.REFERRAL', 'Invite & Earn')}
              icon={InviteAndEarnIcon} // Replace with your icon
            />
          )}
          <NavigationLink
            to={paths.more.savings()}
            label={tr('FRONTEND.HEADER.SAVINGS', 'Savings')}
            icon={SavingsIcon} // Replace with your icon
          />
          {capabilities?.enableCardIssuing && (
            <NavigationLink
              to={paths.card()}
              label={tr('FRONTEND.HEADER.CARDS', 'Cards')}
              icon={CardMenuIcon} // Replace with your icon
            />
          )}
        </div>
      )}
    </SidebarContainer>
  )
}

export default SideBar

const SidebarContainer = styled.div<{ visible?: boolean; isOpen?: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  transition: width 0.3s ease;
  padding-top: 1rem;
  width: ${props => (props.isOpen ? `${props.theme.sidebar.width}px` : `${props.theme.sidebar.widthCollapsed}px`)};
  background-color: #ffff;

  .content {
    display: flex;
    flex-flow: column nowrap;

    overflow-x: hidden;
    overflow-y: auto;
    .sidebar-link {
      display: flex;
      height: 7rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: center;
      text-decoration: none;
      color: #8f96a3;
    }

    .sidebar-link-text {
      margin-top: 1rem;
    }

    .active,
    .sidebar-link:hover {
      background-color: rgba(71, 126, 235, 0.1);
      border-left: 5px solid #0066ff;
      color: #131e3d;
    }
  }

  @media (max-width: ${breakpoints.xl}px) {
    position: relative;
    width: 100%;
    padding: 0 2rem;
    .content {
      padding: 1rem 0;
      flex-flow: row nowrap;
      gap: 2rem;
      width: 100%;
      white-space: nowrap;
      overflow: scroll hidden;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      .sidebar-link {
        font-size: 1.4rem;
        padding: 0 1rem;
      }

      .active,
      .sidebar-link:hover {
        background-color: rgba(71, 126, 235, 0.1);
        border: none;
        border-radius: 1.5rem;
        color: #131e3d;
      }
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      background-image: linear-gradient(to right, transparent, rgba(71, 126, 235, 0.2));
      width: 15%;
    }
  }
`
