import { useContext } from 'react'

import parse from 'html-react-parser'
import styled from 'styled-components'

import { GlobalContext, tr } from 'mmfintech-commons'
import { isOwnerOrAdministrator, useAcceptLegalRequirementsMutation } from 'mmfintech-backend-api'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'

import { LegalRequirementResponse, LegalRequirementType } from 'mmfintech-commons-types'

const links = {
  GENERAL_TERMS: 'https://www.jetonbank.com/general-terms',
  PRICING_POLICY: '/files/Jetonbank_Pricing_Policy.pdf',
  TERMS_OF_USE: 'https://www.jetonbank.com/terms-conditions'
}

interface LegalRequirementsModal {
  customerRole: any
  requirements: LegalRequirementResponse[]
}

export const LegalRequirementsModal = ({ customerRole, requirements }: LegalRequirementsModal) => {
  const { modalHide } = useContext(GlobalContext)

  //todo not localized

  const [acceptLegalRequirements, { error: acceptLegalRequirementsError, isLoading: acceptLegalRequirementsFetching }] =
    useAcceptLegalRequirementsMutation()

  const prepareLink = (type: LegalRequirementType) =>
    `<a href="${links[type]}" target="_blank" rel='noreferrer'>${tr(`MERCHANTS.LEGAL_REQUIREMENTS.${type}`, type.replace(/_/g, ' '))}</a>`

  const prepareLinks = () => {
    if (requirements.length > 1) {
      const list = requirements.map(entry => prepareLink(entry.type))
      const last = list.pop()
      return `${list.join(', ')} and ${last}`
    }

    return prepareLink(requirements[0].type)
  }

  const handleAccept = () => {
    const requirementIds = requirements.map(entry => entry.id)
    acceptLegalRequirements({ requirementIds })
      .unwrap()
      .then(() => {
        modalHide()
      })
      .catch(() => {
        return
      })
  }

  if (isOwnerOrAdministrator(customerRole)) {
    return (
      <ModalWrapper>
        <h3>Important Notice</h3>

        <p>
          We have updated our {parse(prepareLinks())} for Jeton Bank Services. Before you continue using your account,
          please take a moment to review the new terms.
        </p>

        <p>By clicking "Accept" you confirm that you have read and agreed to these terms.</p>

        <ErrorDisplay error={acceptLegalRequirementsError} />

        <div className='buttons'>
          <Button text={'Accept'} color='primary' onClick={handleAccept} loading={acceptLegalRequirementsFetching} />
          {/*<Button text={'Close'} color='secondary' onClick={modalHide} className='button-close' />*/}
        </div>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      <h3>Important Notice</h3>

      <p>
        Please be advised that the account owner or administrator has the authority to review and accept the updated{' '}
        {parse(prepareLinks())} for JetonBank services.
      </p>

      <p>
        If you are not the owner or administrator of this account, please ensure that the authorized person (owner or
        administrator) logs into their account and reviews the new terms. The acceptance of these terms is required for
        the continued use of Jeton Bank services.
      </p>

      <div className='buttons'>
        <Button text={'Close'} color='primary' onClick={modalHide} />
      </div>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  padding: 5rem;
  width: 100%;

  h3 {
    margin: 0 0 2rem;
  }

  a {
    color: #1e63ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .buttons {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    gap: 2rem;

    .button-close {
      width: 30%;
    }
  }
`
