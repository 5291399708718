import { useHistory } from 'react-router-dom'

import CurrencyIcon from '../../components/CurrencyIcon'

import { CurrencyWrapper } from './styled/accounts.styled'
import { Button, DataTable } from 'mmfintech-portal-commons'

import { breakpoints } from '../../constants'
import { paths, useCurrencies } from 'mmfintech-backend-api'
import { copyTextToClipboard, formatMoney, tr, useWindowSize } from 'mmfintech-commons'

import EditIcon from '../../images/icons/edit-account.svg?react'
import CopyIcon from '../../images/icons/copy-icon.svg?react'

export function AccountDataRow({ account, handleEdit }) {
  const { id: accountId, currencyCode, balance, name: accountName, dedicatedMerchantBankAccounts, ibanStatus } = account
  const history = useHistory()

  const [width] = useWindowSize()

  const { getCurrencyPrecision } = useCurrencies()

  const editAccount = e => {
    e && e.stopPropagation()
    handleEdit(account)
  }

  const showIbanStatus =
    (ibanStatus === 'CREATED' || ibanStatus === 'PENDING' || ibanStatus === 'WAITING_ACTION') &&
    dedicatedMerchantBankAccounts.length === 0

  const showTransactions = accountId => {
    history.push(paths.banking.transactions.listByAccountId(accountId))
  }

  return (
    <DataTable.Row
      onClick={() => {
        if (width >= breakpoints.xl) {
          showTransactions(accountId)
        }
      }}
      data-test='table-row'>
      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.CURRENCY', 'Currency')} className='small no-border'>
        <CurrencyWrapper>
          <CurrencyIcon
            currency={currencyCode}
            width='3rem'
            height='3rem'
            circle={false}
            className='mr-1 currency-icon'
          />
          <span data-test='currency'>{currencyCode}</span>
        </CurrencyWrapper>
      </DataTable.Cell>

      <DataTable.Cell className='no-border' caption={tr('FRONTEND.ACCOUNTS.COLUMNS.NAME', 'Account Name')}>
        <span data-test='account-name'>{accountName}</span>
      </DataTable.Cell>

      <DataTable.Cell className='no-border' caption='Account details'>
        <span data-test='account-details'>
          {dedicatedMerchantBankAccounts.length > 0 ? (
            <span className='account-details-iban'>
              IBAN:
              {dedicatedMerchantBankAccounts[0]?.iban}{' '}
              <CopyIcon
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyTextToClipboard(dedicatedMerchantBankAccounts[0]?.iban)
                }}
              />
            </span>
          ) : null}
          {showIbanStatus ? tr('FRONTEND.ACCOUNTS.IBAN_REQUESTED.TITLE', 'IBAN Requested') : null}
        </span>
      </DataTable.Cell>

      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.ACCOUNT_ID', 'ID')} className='small no-border'>
        <span data-test='account-id'>{accountId}</span>
      </DataTable.Cell>

      <DataTable.Cell caption={tr('FRONTEND.ACCOUNTS.COLUMNS.BALANCE', 'Balance')} className='small no-border'>
        <span data-test='balance'>{formatMoney(balance, currencyCode, getCurrencyPrecision(currencyCode))}</span>
      </DataTable.Cell>

      <DataTable.Cell className='edit-cell icon no-border'>
        <span data-test='edit-button' onClick={editAccount} title={'Edit account' /* todo */}>
          <EditIcon />
        </span>
      </DataTable.Cell>

      <DataTable.Cell className='transactions-cell no-border'>
        <Button
          color='round-primary'
          text='View transactions'
          onClick={() => showTransactions(accountId)}
          data-test='transactions-button'
        />
      </DataTable.Cell>
      <DataTable.Cell className='transactions-cell no-border'>
        <Button color='round-primary' text='Edit account' onClick={editAccount} data-test='edit-button' />
      </DataTable.Cell>
    </DataTable.Row>
  )
}
